export default function ProjectShowcase() {
  return (
    <div className="PageHasFixedHeader">

      <div>
        Top of the page
      </div>
      <div>
        Project showcase
      </div>
      <div>
        Project showcase
      </div>
      <div>
        Project showcase
      </div>
      <div>
        Project showcase
      </div>
      <div>
        Project showcase
      </div>
      <div>
        Project showcase
      </div>
      <div>
        Project showcase
      </div>
      <div>
        Project showcase
      </div>
      <div>
        Project showcase
      </div>
      <div>
        Project showcase
      </div>
      <div>
        Project showcase
      </div>
      <div>
        Project showcase
      </div>
      <div>
        Project showcase
      </div>
      <div>
        Project showcase
      </div>
    </div>
  )
}