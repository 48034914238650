import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

type FooterIconProps = {
  packageName: IconPrefix,
  iconName: IconName,
  link: string
}

export default function FooterIcon({packageName, iconName, link}: FooterIconProps) {
  return (
    <a href={link} target = "_blank" rel="noopener noreferrer">
      <span className="icon is-large is-left">
        <FontAwesomeIcon icon={[packageName, iconName]} size="lg"/>
      </span>
    </a>
  )
}
