export default function Faq() {
  return (
    <div className="PageHasFixedHeader">
      <div>
        Top of the page
      </div>
      <div>
        FAQ
      </div>
      <div>
        FAQ
      </div>
      <div>
        FAQ
      </div>
      <div>
        FAQ
      </div>
      <div>
        FAQ
      </div>
      <div>
        FAQ
      </div>
      <div>
        FAQ
      </div>
      <div>
        FAQ
      </div>
      <div>
        FAQ
      </div>
      <div>
        FAQ
      </div>
      <div>
        FAQ
      </div>
      <div>
        FAQ
      </div>
      <div>
        FAQ
      </div>
      <div>
        FAQ
      </div>
    </div>
  )
}