export default function HomePage() {

  return (
    // <div style={{padding: "50px"}} className="has-navbar-fixed-top">
    <div className="PageHasFixedHeader has-navbar-fixed-top">
      Homepage
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
      <div>
        content
      </div>
    </div>
  )
}