import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function HeaderBar() {

  const [burgerIsActive, setBurgerIsActive] = useState(false);

  return (
    <nav className="navbar is-fixed-top is-primary" color="primary">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          Anthony Dang
        </Link>

        <button className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navBarItemSet"
          onClick={() => {
            setBurgerIsActive(!burgerIsActive);
          }}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div id="navBarItemSet" className={`navbar-menu ${burgerIsActive ? "is-active" : ""}`}>
        <div className="navbar-start">
          <Link to="/showcase/projects" className="navbar-item">
            Software
          </Link>

          <Link to="/showcase/photography" className="navbar-item">
            Photography
          </Link>

          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link">
              More
            </div>

            <div className="navbar-dropdown">
              <Link to="/faq" className="navbar-item">
                FAQ
              </Link>
              <Link to="/contact-us" className="navbar-item">
                Get in contact
              </Link>
              <hr className="navbar-divider" />
              <Link to="/contact-us" className="navbar-item">
                Report an issue
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}