import React from 'react';
import FooterIcon from './FooterIcon';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

const footerIcons = [
  {
    packageName: "fab",
    iconName: "github",
    link: "https://github.com/antdangnz"
  },
  {
    packageName: "fab",
    iconName: "linkedin",
    link: "https://www.linkedin.com/in/anthony-dang-nz/"
  },
]

/*
  TODO:
  - Add Contact page link to footer
  - Quick About me/Values
  - Branding
  - Certs

  Other Ideas:
  - Look into Instagram
  - Look into Sitemap
  - Personal Projects section
*/

export default function Footer() {
  return (
    <footer className="footer">
      <div className="level">
        <div className="level-item">
          {
            footerIcons.map((obj, i) => {
              return (
                <FooterIcon
                  key={i}
                  packageName={obj.packageName as IconPrefix}
                  iconName={obj.iconName as IconName}
                  link={obj.link}
                />
              );
            })
          }
        </div>
      </div>
      <div className="content is-small mt-3">
        © Copyright {new Date().getFullYear()} Anthony Dang.
      </div>
    </footer>
  )
}
