import React from 'react';
// import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HeaderBar from './components/header/HeaderBar';
import Footer from './components/footer/Footer';

import HomePage from './pages/HomePage';
import PhotoShowcase from './pages/PhotoShowcase';
import ProjectShowcase from './pages/ProjectShowcase';
import Faq from './pages/Faq';
import ContactUs from './pages/ContactUs';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCalendarAlt, faCheck, faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'

function App() {
  library.add(fab, faCalendarAlt, faCheck, faEnvelope, faLock);

  return (
    <div className="App">
      <Router>
        <HeaderBar/>
        <Switch>
          <Route exact path="/" component={HomePage}/>
          <Route exact path="/showcase/photography" component={PhotoShowcase}/>
          <Route exact path="/showcase/projects" component={ProjectShowcase}/>
          <Route exact path="/faq" component={Faq}/>
          <Route exact path="/contact-us" component={ContactUs}/>
        </Switch>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
