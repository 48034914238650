export default function PhotoShowcase() {
  return (
    <div className="PageHasFixedHeader">
      <div>
        Top of the page
      </div>
      <div>
        Photo showcase
      </div>
      <div>
        Photo showcase
      </div>
      <div>
        Photo showcase
      </div>
      <div>
        Photo showcase
      </div>
      <div>
        Photo showcase
      </div>
      <div>
        Photo showcase
      </div>
      <div>
        Photo showcase
      </div>
      <div>
        Photo showcase
      </div>
      <div>
        Photo showcase
      </div>
      <div>
        Photo showcase
      </div>
      <div>
        Photo showcase
      </div>
      <div>
        Photo showcase
      </div>
      <div>
        Photo showcase
      </div>
      <div>
        Photo showcase
      </div>
    </div>
  )
}